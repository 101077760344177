<template>
    <!-- 搜索结果 -->
    <div class="search">
        <topHeader></topHeader>
        <div class="search_cont">
             <PageContent :title="'搜索结果'" :total="total" :list="noticeList" @getArticleList="getSearch"/>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import axios from '../http/axios';
    import PageContent from '../comm/pageContent.vue'
    export default {
        data(){
            return{
                noticeList:[],
                total:null
            }
        },
        components:{
            PageContent
        },
        mounted(){
            this.getSearch()
        },
        methods:{
            getSearch(page=1,page_number=10,keywords=this.$route.params.searchText){
                axios.post('search',{page,page_number,keywords}).then(res=>{
                    this.noticeList = res.data.data
                    this.total = res.data.total
                })
            }
        }
    }
</script>

<style scoped lang='less'>

</style>